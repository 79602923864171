@import "abstracts/_abstracts";
.VisitorsNotificationList {
    position: relative;
    overflow: hidden;
    animation: rollOpen 2s ease-in-out;
    box-shadow: inset 0 -4px 10px lightgrey;

    // animace otvirani
    @keyframes rollOpen {
        0% {
            max-height: 0;
            overflow: hidden;
        }
        50% {
            max-height: 0;
            overflow: hidden;
        }
        100% {
            max-height: 100vh;
            overflow: hidden;
        }
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: clamp(150px, 220vw, 300px);
        background: linear-gradient(-45deg, transparent 25%,  var(--colorBrand) 35%, var(--colorBrand) 65%, transparent 75%);
        opacity: 0.1;
        z-index: -1;
        top: 0;
        left: 0;
        transform: translate(-100%, 0);
        animation:backgroundStrip 8s ease-in-out infinite;
    }

    // animace pruhu v pozadi
    @keyframes backgroundStrip {
        80% {
            left: 0;
            transform: translate(-100%, 0);
        }
        100% {
            left: 100%;
            transform: translate(100%, 0);
        }
    }
}